import './magazine-teaser.scss';
import { Slider } from '../slider/slider';

class MagazineTeaser {
    constructor (element, options) {
        const defaults = {
            initAttr: 'data-magazineteaser'
        };

        this.settings = Object.assign({}, defaults, options);
        this.$magazineTeaser = element;
        this.magazinTeaserSlider = null;

        this.initialize();
    }

    initialize () {
        this.initSlider(this.$magazineTeaser);
    }

    initSlider (slider) {
        this.magazinTeaserSlider = new Slider(slider, {
            indicators: false,
            slidesPerView: 'auto',
            // centeredSlides: true,
            // centeredSlidesBounds: true,
            // indicatorsInControls: false,
            initAttr: this.settings.initAttr
        });
    }
}

export { MagazineTeaser };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        const $$magazineTeaser = $context.querySelectorAll('[data-magazineteaser="root"]');
        for (let i = 0; i < $$magazineTeaser.length; i++) {
            const $magazineTeaser = $$magazineTeaser[i];
            $magazineTeaser.magazineTeaserApi = new MagazineTeaser($magazineTeaser);
        }
    }
});
